$headline-font: 'freight-big-pro', serif; ;
$copy-font:  'muli', sans-serif; ;
$primary: #031420;
$secondary: #9b6343;
$eggshell: #faf7f2;
$facebook: #3b5998;
$linkedin: #007bb6;
@import "~foundation-sites/scss/foundation";


@include foundation-xy-grid-classes(
  $base-grid: true,
  $margin-grid: true,
  $padding-grid: true,
  $block-grid: true,
  $collapse: true,
  $offset: true,
  $vertical-grid: true
);
@include foundation-flex-grid;
@include foundation-flex-classes;
.show-for-large{
  display: none;
  @media #{$large-up}{
    display: block;
  }
}
.show-for-medium{
  display: none;
  @media #{$medium-up}{
    display: block;
  }
}
*{
  box-sizing: border-box;
}
[data-ani]{
  visibility: hidden;
  animation-duration: 1.5s;
  &.animated{
    visibility: visible;
  }
}
[data-textillate-ani]{
  visibility: hidden;
}
body{
  font-size: 18px;
  font-family: $copy-font;
  // overflow: hidden;
  // width: 100%;
  // height: 100vh;
  overflow-x: hidden;
  &.mobile-active{
    // overflow: hidden;
  }
}
p{
  line-height: 2;
}

#siteContainer{
  position: relative;
  top: 0;
  &.animateDown{
    top: 200px;
    transition: all 1s;
  }
  &.animateBack{
    top: 0;
    transition: all 1s;
  }
}
header{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 9996;
  background: none;
  animation-delay: 1s;
  padding-top: 50px;
  @media #{$medium-up}{
    padding-top: 0;
  }
  .sticky-header & {
    background: #fff;
    .logoLeft{
      img{
        max-width: 100px;
      }
    }
  }
  .logoLeft{
    padding: 1em;
    img{
      max-width: 140px;
      @media #{$medium-up}{
        max-width: 250px;
      }
      transition: all .5s;
    }
  }
}
h1, h2, h3, h4, h5, h6{
  font-family: $headline-font;
  font-weight: normal;
}
img{
  max-width: 100%;
}
.text-center{
  text-align: center;
}
.button{
  display: inline-block;
  padding: 1em 2em;
  background: $primary;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 900;
  letter-spacing: 1px;
  transition: all .5s;
  &:hover{
    background: $secondary;
  }
}
input[type="submit"].button{
  border: none;
  font-size: 1em;
  font-family: $copy-font;
}
.hero{
  position: relative;
  width: 100%;
  background: #333 url('/images/87279381.jpg') no-repeat center center / cover;
  padding: 15em 1em 10em;
  @media screen and (min-width: 1025px){
    height: 100vh;
    padding: 0;
  }
  &::before{
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#000, .36);
  }
  .overlayText{
    position: relative;
    text-align: center;
    width: 100%;
    max-width: 1500px;
    @media screen and (min-width: 1025px){
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    a.button{
      background: none;
      border: 2px solid #fff;
      &:hover{
        background: $primary;
        border: 2px solid $primary;
      }
    }
    h2{
      color: #fff;
      line-height: 1;
      margin: 0;
      font-size: 2em;
      @media #{$medium-up}{
        font-size: 4.5em;
      }
      @media screen and (min-width: 1025px){
        font-size: 8.5vw;
      }
      span{
        display: block;
      }
      span.secondLine{
        opacity: 0;
      }
      .loaded &{
        div:nth-child(odd),
        div:nth-child(even){
          opacity: 1;
          transform: translate(0, 0);
        }
      }
    }
    p{
      color: #fff;
      text-transform: uppercase;
      font-weight: 900;
      font-size: .85em;
      animation-delay: 3s;
      @media #{$medium-up}{
        font-size: 1em;
      }
      @media screen and (min-width: 1025px){
        margin: 1em auto 0;
        font-size: 1.25vw;
      }
    }
    .journeyButton{
      opacity: 0;
      animation-delay: 1s;
    }
  }
}
.featuredInImage{
  max-width: 400px;
  margin: 0 0 1em;
}
.featuredIn{
  position: fixed;
  bottom: 10px;
  left: 20px;
  z-index: 101;
  h2{
    color: #fff;
    margin: 0;
    border-bottom: 1px solid #fff;
    padding: 0 0 10px;
    margin: 0 0 10px;
    font-size: 2em; 
  }
  ul{
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    li{
      display: inline-block;
      margin: 0 20px 0 0;
      &:last-child{
        margin-right: 0;
      }
      img{
        max-width: 200px;
      }
    }
  }
}
.titleContainer{
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 15em 1em 10em;
  @media screen and (min-width: 1025px){
    padding: 0;
    height: 100vh;
  }
  &.bookTitle{
    @media screen and (min-width: 1025px){
      padding: 0;
      height: 70vh;
    }
  }
  .arrow-down{
    font-size: 3em;
    color: #fff;
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 100;
    width: 70px;
    height: 70px;
    text-decoration: none;
    // border: 2px solid #fff;
    border-radius: 100%;
    animation-iteration-count: infinite;
    animation-duration: 10s;
    display: none;
    @media screen and (min-width: 1025px){
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &:hover{
      background: #fff;
      color: $primary;
    }
  }
  .bg-wrapper{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 105%;
    height: 105%;
    background: #333 url('/images/87279381.jpg') no-repeat center center / cover;
    &.book-bg{
      background: #333 url('/images/54433012.jpg') no-repeat center center / cover;
    }
    &.apply{
      background: #333 url('/images/apply-header.jpg') no-repeat center center / cover;
    }
    &.advisor{
      background: #333 url('/images/istockphoto-639000086-1024x1024.jpg') no-repeat center center / cover;
    }
    &.dream{
      background: #333 url('/images/dream-header.jpg') no-repeat center center / cover;
    }
    &.expertise{
      background: #333 url('/images/expertise-header.jpg') no-repeat center center / cover;
    }
    &.team-header{
      background: #333 url('/images/istockphoto-1164878594-1024x1024.jpg') no-repeat center center / cover;
    }
    &.culture-header{
      background: #333 url('/images/culture-header.jpg') no-repeat center center / cover;
    }
    &.journey{
      background: #333 url('/images/istockphoto-537459698-1024x1024.jpg') no-repeat center center / cover;
    }
    &.testimonials{
      background: #333 url('/images/istockphoto-654231348-1024x1024.jpg') no-repeat center center / cover;
    }
    &.story{
      background: #333 url('/images/istockphoto-1137111484-1024x1024.jpg') no-repeat center center / cover;
    }
    &.transition{
      background: #333 url('/images/transition-header.jpg') no-repeat center center / cover;
    }
  }
  &::before{
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#000, .36);
    z-index: 10;
  }
  h1{
    position: relative;
    font-size: 3em;
    max-width: 1200px;
    color: #fff;
    text-align: center;
    line-height: 1;
    margin: 0;
    z-index: 11;
    visibility: hidden;
    &.blog-post-title{
      font-size: 2em;
    }
    @media screen and (min-width: 1025px){
      &.blog-post-title{
        font-size: 4em;
      }
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 8vw;
      transform: translate(-50%, -50%);
      &.bookTitleH1{
        font-size: 5vw;
      }
    }
    div{
      opacity: 0;
      transition: all 1.25s;
      position: relative;
    }
    div:nth-child(odd){
      transform: translate(0, 50px);
    }
    div:nth-child(even){
      transform: translate(0, -50px);
    }
    .loaded &{
      div:nth-child(odd),
      div:nth-child(even){
        opacity: 1;
        transform: translate(0, 0);
      }
    }
  }
}
.int-tier{
  padding: 3em 1em;
  overflow: hidden;
  .blog-post{
    margin-bottom: 4em;
    padding-bottom: 4em;
    border-bottom: 1px solid #ddd;
    .blog-meta{
      border-bottom: 1px dashed #eee;
      padding-bottom: 1em;
      h3, h2{
        margin: 0;
      }
      h2{
        font-size: 2.5em;
      }
      h3{
        font-family: $copy-font;
        font-size: .65em;
        text-transform: uppercase;
        color: #777;
        i{
          margin-right: .5em;
          color: $secondary;
          font-size: 1.35em;
        }
      }
    }
    .about-author{
      background: #f6f6f3;
      border: 1px solid #ddd;
      padding: 2em;
      margin: 1em auto 0;
      h2.author-about{
        font-family: $copy-font;
        letter-spacing: 6px;
        color: #999;
        text-transform: uppercase;
        font-size: .65em;
        font-weight: 900;
        margin: 0;
        line-height: 1;
      }
      h3{
        margin: 0 0 .25em;
        font-size: 3em;
      }
      a{
        color: $primary;
        text-decoration: none;
      }
      p{
        margin: 0;
      }
      ul.author-sm{
        margin: 0;
        padding: 0;
        li{
          display: inline-block;
          margin-right: 10px;
          margin-top: 1em;
          a{
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background: $primary;
            width: 50px;
            height: 50px;
            color: #fff;
            border-radius: 100%;
            &.lmg-fa-linkedin-in{
              background: $linkedin;
              &:hover{
                background: $primary;
              }
            }
            &.lmg-fa-facebook-f{
              background: $facebook;
              &:hover{
                background: $primary;
              }
            }
          }
        }
      }
    }
  }
  @media #{$medium-up}{
    padding: 6em 2em 6em;
  }
  position: relative;
  .testimonials &,
  .our-story &{
    padding: 2em;
    @media #{$large-up}{
      padding: 6em 2em 6em;
    }
  }
  .culture-last{
    text-align: center;
    &.independence-story{
      padding-top: 1em;
      margin-top: 4em;
      border-top: 1px solid #ddd;h2{
        color: $secondary;
      }
    }
    h2{
      font-size: 1.5em;
      @media #{$medium-up}{
        font-size: 2em;
      }
      @media #{$large-up}{
        font-size: 3vw;
      }
    }
    .quote-box{
      border: 20px double $primary;
      // background: $primary;
      padding: 1em;
      @media #{$medium-up}{
        padding: 4vw;
      }
      p{
        color: $primary;
        @media #{$large-up}{
          font-size: 1.15vw;
        }
      }
      .pull-quote{
        border: 1px solid #ddd;
        padding: 1em 2em;
        margin: 0 auto 1em;
        p{
          font-family: $headline-font;
          font-style: italic;
          line-height: 1.15;
          font-size: 1.25em;
          @media #{$medium-up}{
            font-size: 1.75em;
          }
          @media #{$large-up}{
            font-size: 2.35vw;
          }
        }
      }
    }
  }
  .todd-quote-tier{
    text-align: center;
    background: #333 url('/images/icons-background.jpg') no-repeat center bottom / cover fixed;
    position: relative;
    padding: 2em;
    @media #{$large-up}{
      padding: 5vw 2vw 3vw; 
      margin: 5vw auto !important;
    }
    &::before{
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(#000, .65);
    }
    p.quote{
      font-family: $headline-font;
      font-style: italic;
      line-height: 1.15;
      color: #fff;
      margin: 0;
      positioN: relative;
      font-size: 1.5em;
      @media #{$medium-up}{
        font-size: 2em;
      }
      @media #{$large-up}{
        font-size: 3vw;
      }
    }
    p.author{
      text-transform: uppercase;
      font-weight: 900;
      letter-spacing: 2px;
      color: #fff;
      margin: 1em 0 0;
      positioN: relative;
      font-size: .85em;
      @media #{$medium-up}{
        font-size: 1em;
      }
      @media #{$large-up}{
        font-size: 1.25vw;
      }
    }
  }
  .pillars-tier{
    .photo-container{
      position: relative;
      text-align: center;
      img{
        width: 350px;
        @media #{$large-up}{
          width: 100%;
        }
      }
      .photo-cover{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #fff;
        &[data-ani]{
          visibility: visible;
        }
      }
    }
    .tierTitle{
      h2{
        color: $primary;
        margin: 0;
        font-weight: normal;
        line-height: 1;
        font-size: 1.75em;
        @media #{$medium-up}{
          font-size: 2.5em;
        }
        @media #{$large-up}{
          font-size: 3vw;
        }
      }
    }
    .tier-content{
      padding: 10vw 1vw;
      background: #fff;
      position: relative;
      &.nopadding{
        padding: 0;
      }
      h2{
        line-height: 1;
        margin-bottom: .5em;
        @media #{$large-up}{
          margin-left: -25%;
        }
      }
      h2 + p{
        font-size: 1.25em;
      }
    }
    ul{
      margin: 0;
      padding: 0;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      width: 100%;
      text-align: center;
      li{
        width: 25%;
        margin: 4em 0 0;
        list-style: none;
        i{
          font-size: 2.5vw;
          color: $primary;
          margin: 0 auto 10px;
        }
        h3{
          font-size: 1.5vw;
          margin: 0;
        }
      }
    }
  }
  &.values-tier{
    padding: 3em 0em;
    overflow-x: hidden;
    @media #{$medium-up}{
      padding: 6em 2em 6em;
      padding-bottom: 5vw;
    }
    .wrap{
      padding: 10vw 5vw;
      > .inner{
        position: relative;
      }
    }
    i{
      color: #fff;
      font-size: 2em;
      @media #{$large-up}{
        font-size: 4vw;
      }
    }
    h2{
      font-size: 2em;
      @media #{$large-up}{
        font-size: 4vw;
      }
    }
    h3{
      font-size: 1.5em;
      @media #{$large-up}{
        font-size: 1.5vw;
      }
    }
  }
  .wrap{
    width: 90%;
    max-width: 1500px;
    margin: 0 auto;
    position: relative;
    .icons-container{
      padding: 5vw 2vw 8vw;
      background: #333 url('/images/icons-background.jpg') no-repeat center bottom / cover;
      color: #fff;
      position: relative;
      h2{
        font-size: 5vw;
        color: #fff;
        text-align: center;
        position: relative;
        margin: 0 0 5vw;
        line-height: 1;
      }
      &::before{
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(#000, .65);
      }
      .grid-x{
        position: relative;
      }
      p{
        margin: 0;
        color: #fff
      }
      i{
        font-size: 5vw;
      }
      h3{
        font-size: 1.5vw;
      }
    }
    p.leadin-text{
      text-align: center;
      font-family: $headline-font;
      color: $primary;
      font-size: 1.75em;
      line-height: 1.05;
      @media #{$large-up}{
        font-size: 2.75vw;
      }
    }
    ul li, p{
      color: #777;
      line-height: 2;
    }
    img{
      width: 100%;
    }
  }
}
.tier1{
  .photo-container{
    position: relative;
    text-align: center;
    img{
      width: 350px;
      @media #{$large-up}{
        width: 100%;
      }
    }
    .photo-cover{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #fff;
      &[data-ani]{
        visibility: visible;
      }
    }
  }
  .tierTitle{
    h2{
      color: $primary;
      margin: 0;
      font-weight: normal;
      line-height: 1;
      font-size: 2em;
      @media #{$large-up}{
        font-size: 4.75vw;
      }
    }
  }
  .tier-content{
    background: #fff;
    position: relative;
    padding: 0;
    @media #{$medium-up}{
      padding: .25em 2em;
    }
    &.no-padding{
      padding: 0 1vw;
    }
    h2{
      line-height: 1;
      margin-bottom: .5em;
      @media #{$large-up}{
        margin-left: -25%;
      }
    }
    h2 + p{
      font-size: 1.25em;
    }
    .larger-text{
      font-family: $headline-font;
      font-size: 3.75em;
      color: $primary;
      line-height: 1;
      margin: .5em 0 0;
    }
  }
  &.book-tier-1{
    h2{
      @media #{$large-up}{
        margin-left: 0;
        font-size: 3vw;
      }
    }
  }
  &.book-tier-3{
    .cell{
      @media #{$large-up}{
        padding-right: 4vw;
        &.second-cell{
          padding-left: 4vw;
          padding-right: 0;
          border-left: 1px solid $primary;
        }
      }
    }
    h2{
      font-size: 3em;
      @media #{$large-up}{
        margin-top: 0;
        margin-left: 0;
        margin-bottom: 2vw;
        font-size: 4vw;
        text-align: center;
      }
    }
    h3{
      font-size:2em;
      @media #{$large-up}{
        font-size: 2.5vw;
        margin: 0;
      }
    }
  }
}
.book-tier-5{
  .testimonials-container{
    text-align: center;
    border: 4px solid $secondary;
    padding-bottom: 4vw;
    .slick-list{
      transition: all .5s;
    }
    p{
      font-family: $headline-font;
      font-size: 2vw;
      color: $primary;
      margin: 0 0 2vw;
      line-height: 1.25;
    }
    .slideContainer{
      padding: 4vw 4vw 0;
    }
  }
}
.values-tier,
.dream-tier2,
.tier2{
  overflow: hidden;
  @media #{$medium-up}{
    padding: 0em 2em;
  }
  position: relative;
  .wrap{
    margin: 0 auto;
    padding: 10vw 12vw;
    background: url('/images/43730663.jpg') no-repeat center center / cover fixed;
    color: #fff;
    position: relative;
    overflow: hidden;
    p{
      color: #fff;
    }
    p.small-text{
      font-size: .75em;
      font-style: italic;
    }
    .inner-wrap{
      max-width: 1300px;
      margin: 0 auto;
      @media #{$large-up}{
        font-size: 1.25vw;
      }
      h2{
        margin: 0 auto 20px;
        font-weight: normal;
        line-height: 1;
        font-size: 2.25em;
        @media #{$large-up}{
          font-size: 4.25vw;
          margin: 0 auto 3vw;
        }
      }
      .larger-text{
        margin: 50px auto 0;
        p{
          font-size: 2em;
          font-family: $headline-font;
          line-height: 1.15;
          margin: 0;
        }
      }
    }
    &::before{
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(#000, .6);
    }
  }
  &.book-tier-2{
    padding: 0;
    .wrap{
      background: $primary;
      width: 100%;
      max-width: 100%;
      padding: 3em;
      @media #{$large-up}{
        padding: 6vw 2vw;
      }
      &::before{
        display: none;
      }
      *{
        position: relative;
      }
      .inner-wrap{
        width: 100%;
        max-width: 1500px;
        h2{
          margin:0;
          @media #{$large-up}{
            font-size: 2.5vw;
          }
        }
        p{
          @media #{$large-up}{
            font-size: 1vw;
          }
        }
        .cell{
          @media #{$large-up}{
            padding-right: 4vw;
            .cell{
              padding-right: inherit;
            }
          }
        }
        .second-cell{
          @media #{$large-up}{
           padding-right: 0;
            padding-left: 4vw;
            border-left: 1px solid $secondary;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          @media #{$large-up}{
            h2{
              margin-top: 1vw;
            }
            p{
              font-size: 1.25vw;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  &.book-tier-4{
    padding: 0 0 3em;
    @media #{$large-up}{
      padding-bottom: 4vw;
    }
    .wrap{
      padding: 3em;
      @media #{$large-up}{
        padding: 6vw;
      }
      background:url('/images/115277210.jpg') no-repeat right center / cover;
      *{
        position:relative;
      }
      ul.inside-list{
        margin: 2vw 0 0 0;
        padding: 0;
        li{
          color: #fff;
          padding-bottom: 10px;
          margin-bottom: 10px;
          border-bottom: 1px dotted #ccc;
          width: 90%;
          list-style: none;
        }
      }
      .second-cell{
        border-left: 1px solid #fff;
        padding-left: 4vw;
        h2{
          @media #{$large-up}{
            font-size: 2.2vw;
          }
        }
        input[type="submit"]{
          margin-top: 1em;
        }
      }
      .title-cell-chapters{
        display: flex;
        align-items: center;
        h2{
          font-size: 4vw;
          margin:0;
        }
        img{
          width: 3vw;
          margin-right: 1vw;
        }
      }
    }
  }
}
.bookForm{
  ul.radio-list{
    display: flex;
    flex-flow: row wrap;
    margin: 0;
    padding: 0;
    li{
      width: 33.33333%;
      list-style: none;
      color: #fff;
      label{
        @media #{$large-up}{
          font-size: .85vw;
        }
      }
    }
  }
  input[type="submit"]{
    font-size: 1vw;
    background: none;
    border: 2px solid #fff;
    &:hover{
      background: #fff;
      color: $primary;
    }
  }
  input[type="text"]{
    background: none;
    color: #fff;
    border: none;
    width: 95%;
    height: 50px;
    border-bottom: 2px solid #fff;
    font-family: $copy-font;
    @media #{$large-up}{
      font-size: 1vw;
    }
    &:focus{
      outline: none;
      border-bottom: 2px solid $secondary;
    }
    &::placeholder{
      opacity: 1;
      color: #fff;
    }
  }
}
.tier3{
  position: relative;
  .wrap{
    max-width: calc(1500px + 1em);
    .cell{
      padding: .5em;
    }
    .inner-cell{
      padding: 4vw;
      @media screen and (min-height: 1075px){
        padding: 8vw 4vw;
      }
      background: $eggshell;
      position: relative;
      &.text-right{
        text-align: right;
        p.number{
          left: 20px;
          right: auto;
        }
      }
      p.number{
        color: $secondary;
        font-size: 2em;
        margin: 0;
        font-weight: 900;
        position: absolute;
        font-family: $headline-font;
        top: 20px;
        right: 20px;
      }
      h2{
        margin: 0 auto .25em;
        color: $primary;
        line-height: 1;
        font-weight: normal;
        font-size: 2.25em;
        @media #{$medium-up}{
          font-size: 4.75vw;
        }
      }
      h2 + p{
        margin: 0;
        @media #{$large-up}{
          font-size: 1.25vw;
        }
      }
    }
    i{
      color: #fff;
      font-size: 2em;
      display: flex;
      width: 80px;
      height: 80px;
      margin: 0 auto .5em;
      background: $secondary;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
    }
  }
}
.dream-tier1{
  position: relative;
  padding: 6em 1em 2em;
  z-index: 101;
  overflow: hidden;
  .wrap{
    p{
      @media #{$large-up}{
        font-size: 1.15vw;
      }
    }
  }
  .v-container{
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 1em;
    max-width: 400px;;
    img{
      width: 200%;
    }
    svg{
      path{
        fill: $primary;
      }
    }
  }
  .cell{
    padding: 0 15px;
  }
  .wrap{
    // width: calc(100% - 400px);
    max-width: 1250px;
    h2{
      font-size: 2.25em;
      @media #{$large-up}{
        font-size: 5.75vw;
      }
      margin: 0;
      color: $primary;
    }
    p:nth-child(2){
      color: #fff;
      margin-top: 4em;
    }
    // margin-left: 400px;
  }
}
.team-tier{
  .cell{
    position: relative;
    a.overlay-link{
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 101;
    }
  }
}
.dream-tier2{
  padding: 0;
  @media #{$medium-up}{
    padding: 0em 2em;
  }
  .wrap{
    background: url('/images/56530398.jpg') no-repeat left -200px top / cover fixed;
    padding: 2em 2em;
    .inner-wrap{
      position: relative;
      p{
        // font-size: 18px !important;
      }

      .cell{
        .inner-cell{
          padding: 2em;
          position: relative;
          transition: all 1s;
          opacity: 0;
          &.top-left{
            transform: translate(100%, 100%);
          }
          &.top-center{
            transform: translate(0, 100%);
          }
          &.top-right{
            transform: translate(-100%, 100%);
          }
          &.center-right{
            transform: translate(-100%, 0);
          }
          &.center-left{
            transform: translate(100%, 0);
          }
          &.bottom-right{
            transform: translate(-50%, -100%);
          }
          &.bottom-left{
            transform: translate(50%, -100%);
          }
          &.active-bag.backpack-cell{
            opacity: 1;
          }
          &.active-bag.top-left,
          &.active-bag.top-center,
          &.active-bag.top-right,
          &.active-bag.center-right,
          &.active-bag.center-left,
          &.active-bag.bottom-right,
          &.active-bag.bottom-left{
            transform: translate(0, 0);
            opacity: 1;
          }
          &:not(.backpack-cell):hover{
            background: rgba(#000, .6);
          }
          a.overlay-link{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: block;
            z-index: 100;
            cursor: pointer;
          }
          i{
            margin: 0 auto 20px;
            font-size: 3em;
            @media #{$large-up}{
              font-size: 3vw;
            }
            &.lmg-fa-backpack{
              font-size: 11vw;
            }
          }
          h2{
            margin: .25em 0 0;
            font-size: 1.5em;
            @media #{$medium-up}{
              font-size: 2em;
            }
            @media screen and (min-width: 1025px){
              font-size: 1.3vw;
            }
          }
        }
      }
    }
  }
}
.dream-tier4{
  text-align: center;
  background: url('/images/watermark-bg.png') no-repeat center center / 600px;
  overflow: hidden;
  .wrap{
    p{
      font-family: $headline-font;
      line-height: 1.15;
      color: $primary;
      font-size: 1.25em;
      @media #{$medium-up}{
        font-size: 1.75em;
      }
      @media #{$large-up}{
        font-size: 3.5em;
      }
    }
  }
}
.advisor-tier1{
  padding-bottom: 1em;
  overflow: hidden;
  .wrap{
    .photo-container{
      position: relative;
      text-align: center;
      img{
        width: 350px;
        @media #{$large-up}{
          width: 100%;
        }
      }
      .photo-cover{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #fff;
        &[data-ani]{
          visibility: visible;
        }
      }
    }
    h2{
      line-height: 1;
      margin-bottom: .5em;
      font-weight: normal;
      font-size: 2.5em;
      @media #{$large-up}{
        margin-left: -20%;
        font-size: 4.75vw;
      }
    }
    .tier-content{
      padding: 2em;
      p{
        // line-height: 1.05;
        // color: $primary;
        // // font-size: 2.75em;
        // font-family: $headline-font;
        font-size: 1.75em;
        @media #{$large-up}{
          font-size: 1vw;
        }
      }
      p.specialText{
        font-size: 1.75em;
        color: $primary;
        font-family: $headline-font;
        @media #{$large-up}{
          font-size: 2vw;
        }
        &.alt{
          line-height: 1.25;
        }
      }
    }
  }
}
.video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
.videoIntro{
  h2{
    color: $primary;
    margin: 0 0 .5em;
    font-size: 1.5em;
    @media #{$medium-up}{
      font-size: 2.5em;
    }
    @media #{$large-up}{
      font-size: 3vw;
    }
  }
  p{
    @media #{$large-up}{
      padding: 0 0 0 100px;
      max-width: 80%;
    }
  }
}
.advisor-tier2{
  padding-top: 0;
  overflow: hidden;
  p.leadin-text{
    text-align: center;
    font-family: $headline-font;
    color: $primary;
    font-size: 1.75em;
    @media #{$large-up}{
      font-size: 2.75vw;
      line-height: 1.05;
    }
  }
  .inner-cell{
    padding: 4em 1em;
    position: relative;
    margin: .5em;
    background: $eggshell;
    span{
      font-size: 2em;
      font-family: $headline-font;
      color: $secondary;
      position: absolute;
      top: 20px;
      right: 20px;
    }
    h2{
      margin: 0;
      font-weight: normal;
      font-size: 2.5em;
      @media #{$large-up}{
        font-size: 2.5vw;
      }
    }
    p{
      margin: 0;
      @media #{$large-up}{
        font-size: .85vw;
      }
    }
  }
}
.moved-icons{
  h3{
    text-align: center;
    color: $secondary;
    font-size: 1.5em;
    @media #{$medium-up}{
      font-size: 2em;
    }
    @media #{$large-up}{
      font-size: 2vw;
    }
  }
  .cell{
    margin: 0 auto 2em;
    h4{
      margin: 0 0 .5em;
      font-size: 1.25em;
      @media #{$medium-up}{
        font-size: 1.75em;
      }
      @media #{$large-up}{
        font-size: 1.35vw;
      }
    }
  }
  i{
    font-size: 3em;
    @media #{$large-up}{
      font-size: 3vw;
    }
    margin: 0;
  }
  p{
    line-height: 1.5;
    margin: 0;
  }
}
.transition-tier1{
  .wrap{
    @media #{$large-up}{
      text-align: center;
    }
    h2{
      line-height: 1.05;
      color: $primary;
      font-weight: normal;
      font-size: 2.5em;
      @media #{$large-up}{
        font-size: 4.75vw;
      }
    }
    .process-container{
      // background: $primary;
      border: 1px solid $primary;
      margin: 100px auto;
      padding: 1em;
      @media #{$medium-up}{
        padding: 6em 5em;
      }
      @media #{$large-up}{
        text-align: center;
      }
      &.ind-day{
        border: 20px double $primary;
      }
      .image-side{
        position: relative;
        .process-image{
          position: absolute;
          width: 100%;
          top: 50%;
          left: 0;
          height: 350%;
          transform: translate(0, -50%);
          display: none;
          @media #{$large-up}{
            display: block;
          }
          &.dig-deeper{
            background: url('/images/test-process.png') no-repeat center center / contain;
          }
          &.make-the-call{
            background: url('/images/make-the-call.png') no-repeat center center / contain;
          }
          &.independence-day{
            background: url('/images/independence-day.png') no-repeat center center / contain;
          }
        }
      }
      &.notop{
        margin-top: 0;
      }
      .left-text{
        text-align: left;
      }
      h2{
        margin-top: 0;
        font-weight: normal;
      }
      ul{
        margin: 0;
        padding: 0;
        list-style: none;
        li{
          list-style: none;
          color: $primary;
          margin: 0 0 1em;
          @media #{$large-up}{
            margin: 0;
            display: inline-block;
            font-size: 1.5vw;
          }
          &::before{
            content: "";
            width: 15px;
            height: 15px;
            background: url('/images/little-logo-dark.png') no-repeat center center / contain;
            display: inline-block;
            vertical-align: middle;
            margin: 0 20px 0 0;
            @media #{$medium-up}{
              margin: 0 40px;
            }
          }
          @media #{$large-up}{
            &:first-child{
              &::before{
                display: none;
              }
            }
          }
        }
      }
      h2{
        color: $primary;
        line-height: 1.05;
        margin: 0 0 1em;
        font-size: 1.5em;
        @media #{$medium-up}{
          font-size: 2.5em;
          margin: 0 0 .5em;
        }
        @media #{$large-up}{
          font-size: 4.5vw;
        }
      }
    }
  }
}
.testimonials-tier1{
  padding-bottom: 0;
  .wrap{
    h2{
      margin: 0;
      line-height: 1;
      font-size: 2.5em;
      @media #{$large-up}{
        font-size: 5em;
      }
    }
  }
}
.testimonials-tier{
  .wrap{
    text-align: center;
    @media #{$large-up}{
      text-align: left;
    }
    h2{
      font-size: 2.5em;
      @media #{$large-up}{
        font-size: 5em;
      }
    }
    width: 100%;
    max-width: 100%;
    img{
      max-width: 300px;
      @media #{$large-up}{
        max-width: 100%;
      }
    }
    .testimonial-container{
      @media #{$large-up}{
        padding: 4em;
      }
      p{
        font-family: $headline-font;
        line-height: 1.5;
        color: $primary;
        font-size: 1.5em;
        @media screen and (min-width: 1025px){
          font-size: 2em;
        }
        @media screen and (min-width: 1500px){
          font-size: 3em;
        }
        &.pointsmaller{
          @media screen and (min-width: 1500px){
            font-size: 2.8em;
          }
        }
      }
    }
  }
}
.siteFooter{
  background: $primary;
  text-align: center;
  padding: 3em 1em;
  color: #fff;
  a.button{
    border: 2px solid #fff;
    padding: .5em 2em .25em;
    width: 320px;
    &.book-button{
      border: 2px solid $secondary;
      background: $secondary;
      &:hover{
        color: $secondary;
        background: #fff;
        border: 2px solid #fff;
      }
    }
    &:Hover{
      border: 2px solid $secondary;
    }
  }
  .grid-x{
    max-width: 1500px;
    margin: 0 auto;
  }
  h2{
    font-size: 2em;
    &::after{
      display: block;
      content: "";
      width: 100px;
      height: 3px;
      background: $secondary;
      margin: .25em auto 0;
    }
  }
  p.small{
    font-size: 12px;
  }
  a.lmg-fab{
    margin: 0 10px;
    display: inline-flex;
    background: #fff;
    color: $primary;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
  }
  img{
    max-width: 200px;
  }
  a{
    color: #fff;
    text-decoration: none;
  }
}
a.journey-button{
  position: fixed;
  font-size: 1em;
  color: #fff;
  z-index: 9997;
  transition: all .5s;
  border: 2px solid #fff;
  font-family: $copy-font;
  display: inline-block;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  padding: .5em 1em .25em;
  top: 10px;
  left: 10px;
  @media #{$medium-up}{
    padding: .75em 2em .5em;
    top: 17.5px;
    right: 100px;
    left: auto;
  }
  .sticky-header &{
    border: 2px solid $primary;
    color: $primary;
  }
  cursor: pointer !important;
  &:hover{
    background: #fff;
    color: $primary;
  }
}
.menu-toggle{
  position: fixed;
  font-size: 2em;
  color: #fff;
  z-index: 9997;
  transition: all .5s;
  width: 40px; 
  height: 20px;
  cursor: pointer !important;
  top: 20px;
  right: 10px;
  @media #{$medium-up}{
    top: 30px;
    right: 30px;
  }
  > div{
    width: 40px;
    height: 2px;
    background: #fff;
    position: absolute;
    transition: all .5s;
    &.one{
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
    }
    &.two{
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &.three{
      top: 100%;
      left: 50%;
      transform: translate(-50%, -100%);
    }
  }
  .mobile-active &{
    > div{
      background: $primary;
      &.one{
        top: 50%;
       transform: translate(-50%, -50%) rotate(-45deg);
      }
      &.three{
        top: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
      }
      &.two{
        width: 0;
      }
    }
    right: 40px;
  }
  .sticky-header &{
    > div{
      background: $primary;
    }
  }
}
.navSidebar{
  position: fixed;
  top: 0;
  right: 0;
  width: 550px;
  height: 100%;
  background: #fff;
  color: #333;
  padding: 1em;
  z-index: 9997;
  transform: translate(100%, 0%);
  transition: all 1s ease 0s;
  padding: 2em 2em 2em 4em;
  overflow: hidden;
  .mobile-active &{
    transition: all 1s ease 0s;
    transform: translate(0, 0);
    overflow: auto;
  }
  ul{
    margin: 0;
    padding: 0;
    li{
      padding: 1em 0;
      border-bottom: 1px solid #ddd;
      opacity: 0;
      transition: all 1s ease 0s;
      font-size: 1.5rem;
      line-height: 1;
      list-style: none;
      line-height: 1;
      font-family: $headline-font;
      p{
        margin: 0;
      }
      a{
        color: $primary;
        &:hover{
          color: #333;
        }
      }
      .cell:first-child{
        opacity: 0;
        transform: scale(1.5);
        transition: all 1s ease 0s;
      }
      .cell:nth-child(2){
        transition: all 1s ease .25s;
      }
      .mobile-active &{
        opacity: 1;
        transition: all 1s ease 1s;
        .cell:first-child{
          opacity: 1;
          transform: scale(1);
          transition: all 1s ease 1s;
        }
        .cell:nth-child(2){
          transition: all 1s ease 1.25s;
        }
      }
      a{
        font-weight: bold;
        text-decoration: none;
      }
    }
  }
}
.transition-overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9998;
  transform: translate(0, -100%);
  transition: all 1s;
  background: $primary url('/images/7tool-logo-whitea.png') no-repeat center center / 70% auto;
}
@media #{$large-up}{
  .transition-overlay{
  transition: all .5s;
  background: $primary url('/images/7tool-logo-whitea.png') no-repeat center center;
  }
}
.transition-enter-active{
  .transition-overlay{
    transform: translate(0, 0);
  }
}
.transition-enter{
  .transition-overlay{

  }
  .pageContent{
    display: none;
  }
}
.transition-exit {
  .transition-overlay{
    display: none;
  }
}
.transition-enter-done {
  .transition-overlay{
    transform: translate(0, 100%);
  }
  .pageContent{
    display: block;
  }
}
.team-tier{
  .cell{
    transition: all .5s;
    cursor: pointer !important;
    .headshotContainer{
      position: relative;
      line-height: 0;
      overflow: hidden;
      .headshotHover{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: translate(-100%, 0);
        background: rgba($primary, .5);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all .5s;
        p{
          position: relative;
          text-align: center;
          color: #fff;
          z-index: 100;
          text-transform: uppercase;
          font-weight: 900;
          i{
            font-size: 2.5vw;
          }
        }
      }
    }
    &:hover{
      box-shadow: 0 0 100px rgba(silver, 1);
      .headshotContainer{
        .headshotHover{
          transform: translate(0, 0);
        }
      }
    }
    h2{
      margin: 0;
      padding: 0;
      font-size: 3vw;
      line-height: 1;
    }
    h3{
      margin: 0;
      padding: 0;
      font-weight: 900;
      color: $secondary;
      font-size: 1.25em;
    }
  }
}
.pop-up-container{
  position: fixed;
  bottom: 0;
  top: auto;
  left: 0;
  width: 100%;
  height: 0;
  overflow: hidden;
  z-index: 9999;
  background: #fff;
  transition: all 1s;
  &#navPop,
  &#startedPop{
    background: #fff url('/images/watermark-bg.png') no-repeat center center / 700px;
    i{
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid $primary;
      width: 50px;
      height: 50px;
      border-radius: 100%;
      position: absolute;
      top: 30px;
      right: 30px;
      color: $primary;
      cursor: pointer;
      z-index: 1001;
      &:hover{
        background: $primary;
        color: #fff;
      }
    }
  }
  &#navPop{
    background: $primary url('/images/office-photo.jpg') no-repeat center center / cover fixed;
    i{
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid #fff;
      width: 50px;
      height: 50px;
      border-radius: 100%;
      position: absolute;
      top: 30px;
      right: 30px;
      color: #fff;
      cursor: pointer;
      z-index: 1001;
      &:hover{
        background: #fff;
        color: $primary;
      }
    }
    &::before{
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(#000, .8);
    }
    .right-side,
    .left-side{
      .hide-for-large-up{
        display: block;
        text-align: center;
        width: 100%;
        a.button{
          margin: 0 auto;
          background: $secondary;
          &:hover{
            background: $primary;
            color: #fff;
          }
        }
        @media #{$medium-up}{
          display: none;
        }
      }
      h2{
        color: #fff;
        font-size: 1.5em;
        @media #{$large-up}{
          margin: 2.5vw 0 0;
          font-size: 3.25vw;
        }
        @media screen and (min-width: 1300px){
          font-size: 3.75vw;
        }
        @media screen and (min-width: 1500px){
          font-size: 4vw;
        }
        @media screen and (min-width: 1800px){
          font-size: 4.25vw;
        }
        &.first-nav{
        }
      }
      h2 + p{
        margin: 0;
        line-height: 1.5;
        font-size: .85em;
        @media #{$large-up}{
          font-size: 1.15vw;
          margin-left: 2em;
        }
      }
      a{
        color: #fff;
        text-decoration: none;
        &:hover{
          color: $secondary;
        }
      }

      form{
        padding: 0;
        input[type="text"],
        input[type="password"]{
          border-bottom: 1px solid #fff;
          color: #fff;
        }
        input[type="submit"].button{
          background: none;
          border: 2px solid #fff;
        }
      }
    }
    .left-side{
      width: 100%;
      @media #{$large-up}{
        width: 40%;
      }
    }
    .right-side{
      width: 100%;
      @media #{$large-up}{
        width: 60%;
      }
      border-left: 1px; 
      h2{
        font-size: 2em;
        @media #{$large-up}{
          font-size: 2.5vw;
        }
      }
      h2 + p{
        margin: 0;
        margin-left: 0em;
        font-size: 1em;
        line-height: 1;
      }
      .whitePaperForm{
        padding: 2em 2em;
        margin-top: 2em !important;
        border: 2px solid #fff;
        max-width: 700px;
        p{
          margin-bottom: 0;
          color: #fff;
        }
      }
      p.shorten{
        max-width: 700px;
        a.button{
          background: none;
          border: 2px solid #fff;
          padding: 1em 1.5em .65em;
          width: 300px;
          &:hover{
            background: #fff;
            color: $primary;
          }
          &.book-button{
            border: 2px solid $secondary;
            background: $secondary;
            &:hover{
              color: $secondary;
              background: #fff;
              border: 2px solid #fff;
            }
          }
        }
      }
      h2.formtitle{
        line-height: 1;
        margin: 0;
        span{
          display: block;
          font-family: $copy-font;
          text-transform: uppercase;
          font-size: .5em;
          @media #{$large-up}{
            font-size: 1vw;
          }
        }
      }
    }
  }
  &#startedPop{
    .right-side{
      input[type="text"]{
        padding: 0;
        font-size: .85em;
        @media screen and (min-height: 800px){
          font-size: 1em;
          padding: 1em;
        }
      }
      textarea{
        font-size: .85em;
        padding: 0;
        @media screen and (min-height: 800px){
          font-size: 1em;
          padding: 1em;
        }
      }
    }
  }
  &::before{
    // content: "";
    // display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#000, .85);
  }
  .left-side{
    position: relative;
    text-align: left;
    margin-top: 5em;
    padding: 2em 2em 0;
    text-align: center;
    .nav-container{
      margin-bottom: 2em;
      @media #{$medium-up}{
        margin-bottom: 0;
      }
    }
    @media #{$large-up}{
      margin-top: 0;
      text-align: left;
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
      padding: 2em 5em;
    }
    p{
      color: #777;
    }
    h3{
      font-size: 2.75vw;
      margin: 0;
      line-height: 1;
      + p{
        margin-top: .25em;
      }
    }
    a{
      color: #777;
      text-decoration: none;
      &:hover{
        color: $primary;
      }
    }
  }
  .right-side{
    padding: 2em 5em;
    position: relative;
    @media #{$large-up}{
      position: absolute;
      top: 0;
      right: 0;
      width: 50%;
      height: 100%;
    }
    p{
      color: #777;
    }
  }
  .right-side,
  .left-side{
    > div{
      width: 100%;
      h2{
        font-weight: normal;
        margin: 0 0 .25em;
        color: $primary;
        font-size: 2.5em;
        @media #{$large-up}{
          font-size: 4vw;
        }
      }
      h2 + p{
        @media #{$large-up}{
          font-size: 1.05vw;
        }
      }
      .contact-container,
      form{
        padding: 1em;
        input[type="text"],
        input[type="password"],
        textarea{
          display: block;
          border: none;
          border-bottom: 1px solid $primary;
          padding: 1em;
          width: 100%;
          font-size: 1em;
          font-family: $copy-font;
          color: $primary;
          background: none;
        }
        textarea{
          height: 100px;
        }
      }
    }
  }
  .inner-pop-container{
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 1500px;
    transform: translate(-50%, -50%);
    .team-pop{
      overflow: auto;
      max-height: 250px;
      @media screen and (min-height: 800px){
        max-height: 420px;
      }
      .title-meta{
        padding-left: 1em;
        border-left: 5px solid $secondary;
      }
      h2{
        font-size: 2vw;
        margin: 0;
        padding: 0;
      }
      h3{
        margin: 0;
      }
    }
    &.team-pop{
      width: 100%;
      max-width: 1000px;
      i{
        margin: 30px auto 0;
        &::after{
          height: 30px;
        }
      }
      a.button{
        padding: .25em  2em;
        font-size: .65em;
        @media screen and (min-height: 800px){
          padding: .5em  2em;
          font-size: 1em;
        }
      }
      .photo-gallery{
        display: flex;
        justify-content: space-around;
        width: 50%;
        margin: 0 auto;
        padding: 2em .5em .5em;
        @media screen and (min-height: 800px){
          width: 100%;
        }
        > div{
          display: flex;
          img{
            width: 100%;
            object-fit: cover;
            font-family: "object-fit: cover;";
          }
        }
      }
      .cell:first-child{
        text-align: center;
        img{
          max-width: 150px;
          @media screen and (min-height: 800px){
            max-width: 100%;
          }
        }
      }
     }
    i{
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid $primary;
      width: 50px;
      height: 50px;
      margin: 120px auto 0;
      border-radius: 100%;
      position: relative;
      color: $primary;
      cursor: pointer;
      &:hover{
        background: $primary;
        color: #fff;
      }
      &::after{
        content: "";
        width: 2px;
        height: 100px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -100%);
        background: $primary;
      }
    }
    h2{
      margin: 0;
      color: $primary;
      font-size: 2.5em;
      @media screen and (min-height: 800px){
        font-size: 5em;
      }
    }
    p{
      color: #555;
    }
  }
  &.active-pop{
    height: 100%;
    top: 0;
  }
}
.cta-bar {
  padding: 10px 10px 10px 30px;
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 9997;
  background: #fff;
  transform: translate(0, 120%);
  transition: all .5s ease-in 1s;
  span{
    line-height: 0;
  }
  .loaded &{
    transform: translate(0, 0);
  }
  p{
    margin: 0
  }
  .cta-button{
    font-size: 1.5em;
    font-family: $headline-font;
    display: inline-block;
    position: relative;
    border: 2px solid $secondary;
    padding: 5px 10px 5px 30px;
    text-decoration: none;
    color: #000;
    cursor: pointer !important;
    span{
      position: relative;
      z-index: 100;
      &:nth-child(2){
        display: inline-block;
        font-size: .5em;
        position: absolute;
        right: 10px;
        bottom: 10px;
        font-weight: 900;
      }
    }
    &:hover{
      &::before{
        width: 0;
      }
    }
    &::after{
      content: "";
      display: block;
      width: 40px;
      height: 30px;
      background: #fff url("/images/icon-arrow.svg") no-repeat center center / contain;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(-60%, -50%);
    }
    &::before{
      content: "";
      display: block;
      position: absolute;
      width: 105%;
      height: 105%;
      background: #fff;
      top: -2.5%;
      right: -2.5%;
      transition: all .5s;
      display: none;
    }
  }
}
.inactive-pop{
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999;
  background: #fff;
  padding: 4em 2em 2em;
  border-radius: 10px;
  text-align: center;
  width: 800px;
  max-width: 90%;
  opacity: 0;
  left: -9999999px;
  transition: opacity .5s ease 0s, left 0s ease .5s;
  .active-timeout &{
    left: 50%;
    opacity: 1;
    transition: opacity .5s ease 0s, left 0s ease 0s;
  }
  i{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid $primary;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    position: absolute;
    top: 20px;
    right: 20px;
    color: $primary;
    cursor: pointer;
    z-index: 1001;
    &:hover{
      background: $primary;
      color: #fff;
    }
  }
  h2{
    line-height: 1.25;
    margin: 0 0 .5em;

    @media #{$large-up}{
      font-size: 2.5vw;
    }
  }
  input[type="text"],
  input[type="email"]{
    width: 100%;
    height: 65px;
    padding: .5em 1em;
    font-size: 1.25em;
    border: 2px solid $primary;
    &::placeholder{
      color: $primary;
    }
  }
}
.inactive-cast{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(#000, .5);
  z-index: 999998;
  left: -999999px;
  opacity: 0;
  transition: opacity .5s ease 0s, left 0s ease .5s;
  .active-timeout &{
    left: 0;
    opacity: 1;
    transition: opacity .5s ease 0s, left 0s ease 0s;
  }
}
.formContainer{
  max-width: 1200px;
  margin: 0 auto;
  textarea{
    display: block;
    border: none;
    border-bottom: 1px solid $primary;
    padding: 1em;
    width: 100%;
    font-size: 1em;
    font-family: $copy-font;
    color: $primary;
    background: none;
  }
  button{
    background: $primary;
    color: #fff;
    text-transform: uppercase;
    border: none;
    padding: 1em 2em;
    font-size: 1.25em;
    &:hover{
      background: $secondary;
    }
  }
  textarea{
    height: 100px;
  }
}
.sendOff{
  position: absolute;
  left: -99999999px;
}
.bg-flex-wrapper{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.bg-flex{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .caption{
    position: absolute;
    bottom: 10px;
    left: 10px;
    line-height: 1;
    color: #fff;
    z-index: 101;
    font-size: .85em;
    @media #{$large-up}{
      font-size: 1.15em;
    }
    p{
      line-height: 1.15;
      font-weight: bold;
      font-size: .9em;  
    }
  }
  .cell{
    height: 100vh;
  }
  &.random1{
    .cell{
      &:nth-child(1){
        background: url('/images/dreambg-1.png') no-repeat center center / cover;
      }
      &:nth-child(2){
        background: url('/images/dreambg-2.png') no-repeat center center / cover;
      }
      &:nth-child(3){
        background: url('/images/dreambg-3.png') no-repeat center center / cover;
      }
    }
  }
  &.random2{
    .cell{
      &:nth-child(1){
        background: url('/images/dreambg-4.jpg') no-repeat center center / cover;
      }
      &:nth-child(2){
        background: url('/images/dreambg-5.jpg') no-repeat center center / cover;
      }
      &:nth-child(3){
        background: url('/images/dreambg-6.jpg') no-repeat center center / cover;
      }
    }
  }
  &.random3{
    .cell{
      &:nth-child(1){
        background: url('/images/dreambg-7.jpg') no-repeat center center / cover;
      }
      &:nth-child(2){
        background: url('/images/dreambg-8.jpg') no-repeat center center / cover;
      }
    }
  }
  &.random4{
    .cell{
      &:nth-child(1){
        background: url('/images/dreambg-9.jpg') no-repeat center center / cover;
      }
      &:nth-child(2){
        background: url('/images/dreambg-10.jpg') no-repeat center center / cover;
      }
    }
  }
  &.random5{
    .cell{
      &:nth-child(1){
        background: url('/images/dreambg-11.jpg') no-repeat center center / cover;
      }
      &:nth-child(2){
        background: url('/images/dreambg-12.jpg') no-repeat center center / cover;
      }
    }
  }
  &.random6{
    .cell{
      &:nth-child(1){
        background: url('/images/dreambg-13.jpg') no-repeat center center / cover;
      }
      &:nth-child(2){
        background: url('/images/dreambg-14.jpg') no-repeat center center / cover;
      }
      &:nth-child(3){
        background: url('/images/dreambg-15.jpg') no-repeat center center / cover;
      }
    }
  }
  &.random7{
    .cell{
      &:nth-child(1){
        background: url('/images/dreambg-16.jpg') no-repeat center center / cover;
      }
      &:nth-child(2){
        background: url('/images/dreambg-17.jpg') no-repeat center center / cover;
      }

    }
  }
  &.random8{
    .cell{
      &:nth-child(1){
        background: url('/images/dreambg-18.jpg') no-repeat center center / cover;
      }
      &:nth-child(2){
        background: url('/images/dreambg-19.jpg') no-repeat center center / cover;
      }
      &:nth-child(3){
        background: url('/images/dreambg-20.jpg') no-repeat center center / cover;
      }
    }
  }
  &.random9{
    .cell{
      &:nth-child(1){
        background: url('/images/dreambg-21.jpg') no-repeat center center / cover;
      }
      &:nth-child(2){
        background: url('/images/dreambg-22.jpg') no-repeat center center / cover;
      }
    }
  }
  &.random10{
    .cell{
      &:nth-child(1){
        background: url('/images/dreambg-23.jpg') no-repeat center center / cover;
      }
      &:nth-child(2){
        background: url('/images/dreambg-24.jpg') no-repeat center center / cover;
      }
    }
  }
  &.random11{
    .cell{
      &:nth-child(1){
        background: url('/images/dreambg-25.jpg') no-repeat center center / cover;
      }
      &:nth-child(2){
        background: url('/images/dreambg-26.jpg') no-repeat center center / cover;
      }
    }
  }
}

.hide-for-large-up{
  display: block;
  text-align: center;
  a.button{
    margin: 0 auto;

  }
  @media #{$medium-up}{
    display: none;
  }
}